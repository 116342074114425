import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  NextPage,
} from "next";
import React from "react";
import { BpfdeployApi } from "lib/api-sdk";
import { Org, Nodepool, User } from "../types";
import { isErr, Ok } from "rustic";
import { AppResult, PageResult } from "lib/app-result";

export interface UserAuth {
  user: User;
  session: { org: Org; nodepools: Nodepool[] };
}

export const AuthContext = React.createContext<PageResult<UserAuth>>(
  AppResult.authErr()
);

export const userAuthFromCtx = async (
  ctx: GetServerSidePropsContext
): Promise<PageResult<{ api: BpfdeployApi; auth: UserAuth }>> => {
  const { cookies } = ctx.req;
  const token = cookies.token;
  if (!token) {
    return AppResult.authErr();
  }
  try {
    const api = BpfdeployApi.create({ token });
    const res = await api.getUser();
    // TODO: Should check here if resp is not 200
    if (isErr(res)) {
      return AppResult.authErr();
    } else {
      // TODO: If there are no nodepools then auth says yes but the UI
      // will most definitely break. How do we handle this? Error logging
      // and in the UI
      return Ok({ api, auth: res.data });
    }
  } catch (err) {
    // TODO: Log error
    return AppResult.authErr();
  }
};

export const withUserAuth: GetServerSideProps<{
  auth: PageResult<UserAuth>;
}> = async (ctx: GetServerSidePropsContext) => {
  const res = await userAuthFromCtx(ctx);
  if (isErr(res)) {
    return { props: { auth: AppResult.authErr() } };
  }

  return { props: { auth: Ok(res.data.auth) } };
};

// We need this Omit here because if T includes an auth property, then TypeScript
// warns us that type safety is gone.
// See: https://stackoverflow.com/questions/65264681/typing-a-redirect-hoc-in-typescript-with-next-js
export function withPageAuth<T>(Page: NextPage<Omit<T, "auth">>) {
  return function withPageAuthFC(
    withPageAuthProps: { auth: PageResult<UserAuth> } & T
  ) {
    const { auth, ...restOfProps } = withPageAuthProps;
    return (
      <AuthContext.Provider value={auth}>
        <Page {...restOfProps} />
      </AuthContext.Provider>
    );
  };
}
