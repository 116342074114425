import { StatusCodes } from "http-status-codes";
import { API_BASE } from "lib/consts";
import { Err, Ok, Result, ResultErr, isOk, ResultOk, isErr } from "rustic";
import { captureException } from "./errors";
import {
  ApiKey,
  BpfMap,
  BpfProgram,
  IdType,
  Node,
  NodeWithoutFeatures,
  Org,
  Nodepool,
  User,
} from "./types";

// TODO: While we liberally use this SDK alongside the rustic library
// across the app, we'll want to consider removing this peer dependency when
// we're ready to ship this as an npm library for other clients

export namespace ApiResponse {
  export enum ErrorType {
    AUTH,
    UNKNOWN, // Catch all
  }

  export interface Error<E extends ErrorType, C> {
    type: E;
    ctx: C;
  }

  // TODO: Could by Symbol
  export function authErr<O>(): Response<O> {
    return Err({
      type: ErrorType.AUTH,
      ctx: undefined,
    });
  }

  export function unknownErr<O>(msg: string): Response<O> {
    return Err({
      type: ErrorType.UNKNOWN,
      ctx: msg,
    });
  }

  export function allOk<X, Y>(
    responses: readonly [Response<X>, Response<Y>]
  ): responses is [ResultOk<X>, ResultOk<Y>];

  export function allOk<X, Y, Z>(
    responses: readonly [Response<X>, Response<Y>, Response<Z>]
  ): responses is [ResultOk<X>, ResultOk<Y>, ResultOk<Z>];

  export function allOk<X, Y, Z, A>(
    responses: readonly [Response<X>, Response<Y>, Response<Z>, Response<A>]
  ): responses is [ResultOk<X>, ResultOk<Y>, ResultOk<Z>, ResultOk<A>];

  export function allOk<R>(
    responses: readonly Response<R>[]
  ): responses is ResultOk<R>[] {
    return !responses.some(isErr);
  }

  export function anyAuth<X>(responses: readonly [Response<X>]): boolean;

  export function anyAuth<X, Y>(
    responses: readonly [Response<X>, Response<Y>]
  ): boolean;

  export function anyAuth<X, Y, Z>(
    responses: readonly [Response<X>, Response<Y>, Response<Z>]
  ): boolean;

  export function anyAuth<X, Y, Z, A>(
    responses: readonly [Response<X>, Response<Y>, Response<Z>, Response<A>]
  ): boolean;

  export function anyAuth<R>(responses: readonly Response<R>[]): boolean {
    return responses.some(
      (resp) => isErr(resp) && resp.data.type === ErrorType.AUTH
    );
  }
}

type ResultAuthErr = ApiResponse.Error<ApiResponse.ErrorType.AUTH, undefined>;

type ResponseErrors =
  | ResultAuthErr
  | ApiResponse.Error<ApiResponse.ErrorType.UNKNOWN, string>;

// Settled on returning errors rather than throwing.
// Benefits include serializability and forcing user to be aware of them
export type Response<T> = Result<T, ResponseErrors>;

export function isApiErr(
  response: Response<unknown>
): response is ResultErr<ResponseErrors> {
  if (isOk(response)) {
    return false;
  }
  return true;
}

type BpfdeployApiOptions = Readonly<
  Partial<{
    token: string;
  }>
>;

// TODO: Use etags or If-Modified-Since header to determine if the data
// on the server has changed
// TODO: Response schemas here should be autogenerated through OpenAPI Swagger
// rather than hand typed
abstract class BpfdeployApiBase {
  protected readonly token?: string;
  protected readonly apiBase: string;

  protected constructor(opts: BpfdeployApiOptions = {}) {
    const { token } = opts;
    const isBrowser = typeof window === "object";
    if (isBrowser && token) {
      throw new Error("[BpfdeployApi]: detected in browser but was set token");
    }
    this.token = token;
    this.apiBase = API_BASE;
  }

  protected async _fetch<T>(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response<T>> {
    if (typeof window === "object") {
      // TODO: Should be "same-origin" in production?
      init.credentials = "include";
    } else if (!this.token) {
      // No token specified in none browser environment
      // TODO: Is this isomorphic enough?
      // TODO: What happens in the case where a user with no cookies goes to
      // a nodepools site. We crash... not good.
      throw new Error(
        "[BpfdeployApi]: detected NOT in browser but was not set token"
      );
    } else {
      init.headers = Object.assign({}, init.headers, {
        Authorization: `apiKey ${this.token}`,
      });
    }

    try {
      // Will throw if using node-fetch
      const fetchResponse = await fetch(input, init);

      if (fetchResponse.status === StatusCodes.UNAUTHORIZED) {
        return ApiResponse.authErr();
      }

      if (fetchResponse.status !== StatusCodes.OK || !fetchResponse.ok) {
        return ApiResponse.unknownErr("Unknown");
      }

      let data: T;
      try {
        data = await fetchResponse.json();
      } catch (err) {
        captureException(err);
        return ApiResponse.unknownErr("JSON parsing");
      }

      return Ok(data);
    } catch (err) {
      captureException(err);
      return ApiResponse.unknownErr("failed to fetch");
    }
  }

  protected async post<P extends {}, T>(
    input: RequestInfo,
    body: P,
    init: RequestInit = {}
  ): Promise<Response<T>> {
    init.headers = Object.assign({}, init.headers, {
      "Content-Type": "application/json",
    });
    init.body = JSON.stringify(body);
    return this._fetch<T>(input, Object.assign({}, init, { method: "POST" }));
  }

  protected async delete<T>(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response<T>> {
    return this._fetch<T>(input, Object.assign({}, init, { method: "DELETE" }));
  }
}

export class BpfdeployApi extends BpfdeployApiBase {
  private constructor(opts: BpfdeployApiOptions = {}) {
    super(opts);
  }

  static create(opts: BpfdeployApiOptions = {}): BpfdeployApi {
    return new BpfdeployApi(opts);
  }

  nodepoolInstance(nodepoolId: IdType): BpfdeployApiNodepool {
    return new BpfdeployApiNodepool(nodepoolId, { token: this.token });
  }

  createNodepool(nodepoolName: string) {
    return this.post(`${this.apiBase}/nodepools`, { name: nodepoolName });
  }

  getUser() {
    return this._fetch<{ user: User; session: { org: Org; nodepools: Nodepool[] } }>(
      `${this.apiBase}/user`
    );
  }

  // TODO: Should this be part of the SDK or an internal call?
  resetUserPassword(oldPassword: string, newPassword: string) {
    return this.post<{ old: string; new: string }, { status: "Success" }>(
      `${this.apiBase}/user/reset-password`,
      {
        old: oldPassword,
        new: newPassword,
      }
    );
  }

  // TODO: This should not be part of the SDK?
  getOrgApiKeys() {
    return this._fetch<{ api_keys: ApiKey[] }>(`${this.apiBase}/org/api-keys`);
  }
}

export class BpfdeployApiNodepool extends BpfdeployApiBase {
  protected readonly nodepoolId: IdType;
  protected readonly apiPrefix: string;

  constructor(nodepoolId: IdType, opts: BpfdeployApiOptions) {
    super(opts);
    this.nodepoolId = nodepoolId;
    this.apiPrefix = `${this.apiBase}/nodepools/${this.nodepoolId}`;
  }

  getNodepool() {
    return this._fetch<{ nodepool: Nodepool }>(this.apiPrefix);
  }

  updateNodepool(fields: { name: string }) {
    return this.post(this.apiPrefix, fields);
  }

  deleteNodepool() {
    return this.delete(this.apiPrefix);
  }

  getAllNodes() {
    return this._fetch<{ nodes: NodeWithoutFeatures[] }>(
      `${this.apiPrefix}/nodes`
    );
  }

  getAllBpfPrograms() {
    return this._fetch<{ bpf_programs: BpfProgram[] }>(
      `${this.apiPrefix}/bpfprograms`
    );
  }

  getAllBpfMaps() {
    return this._fetch<{ bpf_maps: BpfMap[] }>(`${this.apiPrefix}/bpfmaps`);
  }

  getNode(nodeId: IdType) {
    return this._fetch<{ node: Node }>(`${this.apiPrefix}/nodes/${nodeId}`);
  }

  nodeInstance(nodeId: IdType) {
    return new BpfdeployApiNode(nodeId, this.nodepoolId, { token: this.token });
  }

  // TODO: Should not be part of SDK?
  createApiKey(title: string) {
    return this.post<
      { title: string },
      { api_key: { key: string; created_at: Date } }
    >(`${this.apiPrefix}/api-keys`, { title });
  }
}

export class BpfdeployApiNode extends BpfdeployApiBase {
  protected readonly nodepoolId: IdType;
  protected readonly nodeId: IdType;
  protected readonly apiPrefix: string;

  constructor(nodeId: IdType, nodepoolId: IdType, opts: BpfdeployApiOptions) {
    super(opts);
    this.nodepoolId = nodepoolId;
    this.nodeId = nodeId;
    this.apiPrefix = `${this.apiBase}/nodepools/${this.nodepoolId}/nodes/${nodeId}`;
  }

  getNode() {
    return this._fetch<{ node: Node }>(this.apiPrefix);
  }

  getBpfProgram(objectId: IdType) {
    return this._fetch<{ bpf_program: BpfProgram }>(
      `${this.apiPrefix}/bpfprograms/${objectId}`
    );
  }

  getBpfMap(objectId: IdType) {
    return this._fetch<{ bpf_map: BpfMap }>(
      `${this.apiPrefix}/bpfmaps/${objectId}`
    );
  }

  getBpfMaps() {
    return this._fetch<{ bpf_maps: BpfMap[] }>(`${this.apiPrefix}/bpfmaps`);
  }

  getBpfPrograms() {
    return this._fetch<{ bpf_programs: BpfProgram[] }>(
      `${this.apiPrefix}/bpfprograms`
    );
  }
}
