import Image from "next/image";
import LogoLargeSVG from "../../public/assets/logolarge.svg";
import LogoLargeWhiteSVG from "../../public/assets/logolargewhite.svg";
import LogoSmallSVG from "../../public/assets/logosmall.svg";
import LogoSmallWhiteSVG from "../../public/assets/logosmallwhite.svg";

interface Props {
  white?: boolean;
  small?: boolean;
  width?: number;
}

export default function Logo(props: Props) {
  const { white, small = false, width } = props;
  let logo;
  if (white) {
    if (small) {
      logo = LogoSmallWhiteSVG;
    } else {
      logo = LogoLargeWhiteSVG;
    }
  } else if (small) {
    logo = LogoSmallSVG;
  } else {
    logo = LogoLargeSVG;
  }
  return (
    <Image
      alt="bpfdeploy.io logo"
      priority
      src={logo}
      width={width || (small ? 53 : 170)}
      className="rounded-sm"
    />
  );
}
