import * as Sentry from "@sentry/nextjs";

export async function captureException(err: any, shouldFlush: boolean = false) {
  if (process.env.NODE_ENV === "production") {
    Sentry.captureException(err);

    if (shouldFlush) {
      await Sentry.flush(2000);
    }
  } else {
    console.error(`[captureException]`, err);
  }
}
