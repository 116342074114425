import { Fragment, PropsWithChildren, useContext } from "react";
import Head from "next/head";
import Link from "next/link";
import Logo from "./Logo";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import { AuthContext } from "lib/server/withAuth";
import { isOk } from "rustic";

const headerNav = [
  {
    title: "Words",
    href: "https://words.bpfdeploy.io/blog",
    testId: "header-blog-link",
  },
  {
    title: "Twitter",
    href: "https://twitter.com/bpfdeploy",
    testId: "header-twitter-link",
  },
  {
    title: "Github",
    href: "https://github.com/bpfdeploy-io",
    testId: "header-github-link",
  },
];

export default function LandingPage(
  props: PropsWithChildren<{ title: string; description: string }>
) {
  const { title, description, children } = props;
  if (!description) {
    console.error("No description on page");
  } else if (description.length > 160) {
    console.error(
      `Meta description tag on page too long: ${description.length} characters`
    );
  }
  const auth = useContext(AuthContext);
  return (
    <>
      <Head>
        {description && (
          <meta key="description" name="description" content={description} />
        )}
        <title>{title}</title>
      </Head>
      <div className="bg-violet-600 py-3 px-3">
        <p className="text-center text-white">
          bpfdeploy.io is currently in{" "}
          <span className="font-medium underline">private beta</span>
        </p>
      </div>
      <Popover>
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 pt-2 sm:px-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-start justify-between w-full lg:w-auto">
              <div className="py-1 lg:flex">
                <span className="sr-only">bpfdeploy.io</span>
                <Link href="/">
                  <a className="block flex-none" data-test="logo-index-link">
                    <Logo />
                  </a>
                </Link>
                <div className="hidden ml-4 lg:flex lg:flex-row">
                  {headerNav.map(({ title, href, testId }) => (
                    <div key={title}>
                      <Link href={href} passHref>
                        <a
                          className="inline-flex px-4 py-2 text-base font-normal text-gray-500 hover:text-gray-600"
                          data-test={testId}
                        >
                          {title}
                        </a>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-300">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon
                    className="h-6 w-6"
                    aria-hidden="true"
                    data-test="expand-menu"
                  />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden lg:block text-right">
            {isOk(auth) ? (
              <Link href="/app/nodepools" passHref>
                <a className="inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-gray-500 hover:text-gray-600">
                  Open
                </a>
              </Link>
            ) : (
              <Link href="/login" passHref>
                <a
                  className="inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-gray-500 hover:text-gray-600"
                  data-test="login-link"
                >
                  Login
                </a>
              </Link>
            )}
          </div>
        </nav>
        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-14 right-2 w-2/3 p-2 transition transform origin-top-right lg:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              {headerNav.map(({ title, href, testId }) => (
                <Link key={title} href={href} passHref>
                  <a
                    className="block px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50"
                    data-test={testId}
                  >
                    {title}
                  </a>
                </Link>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <main className="min-h-screen">{children}</main>
      <footer className="px-4 py-8">
        <div className="max-w-7xl mx-auto sm:px-6 text-center">
          <Link href="/">
            <a>
              <Logo />
            </a>
          </Link>
        </div>
        <p className="text-center text-base text-gray-400">
          &copy; 2022 • bpfdeploy.io • All rights reserved.
        </p>
      </footer>
    </>
  );
}
