import { Err, Result, ResultErr } from "rustic";

export enum PageErrorType {
  AUTH,
  UNKNOWN,
}

export interface PageError<E extends PageErrorType, C> {
  type: E;
  ctx: C;
}

type PageAuthErr = PageError<PageErrorType.AUTH, null>;
export type PageResultErr =
  | PageAuthErr
  | PageError<PageErrorType.UNKNOWN, { msg: string }>;

export type PageResult<T> = Result<T, PageResultErr>;

export namespace AppResult {
  export function isAuthErr(
    err: ResultErr<PageResultErr>
  ): err is ResultErr<PageAuthErr> {
    return err.data.type === PageErrorType.AUTH;
  }

  export function authErr<O>(): PageResult<O> {
    return Err({ type: PageErrorType.AUTH, ctx: null });
  }

  export function unknownErr<O>(msg: string): PageResult<O> {
    return Err({ type: PageErrorType.UNKNOWN, ctx: { msg } });
  }
}
